import { isAfter, isBefore, isValid, parse, parseISO, startOfDay } from 'date-fns'

import { FEEDBACK_MAX_LENGTH, NAME_MIN_LENGTH } from '@/constants/others'
import { REGEX_PATTERN as RGX } from '@/constants/regex'

/**
 * Predefined validation for each form field.
 *
 * ADD NEW:
 * - Add the field name to the type: "ValidationFields"
 * - Create validation function. Each validation should have a short comment on top stating what is trying to validate.
 * - Add a new key to the object "validationFnPerField" linking the field with its function.
 * - Each validation function should return either a string or null. The string will be a path to the error message to be translated.
 * - All error messages are located in the file 'locales/(en/fr)common.json'
 */

export type ValidationFields =
  | 'business_name'
  | 'consent'
  | 'email'
  | 'feedbackComment'
  | 'first_name'
  | 'futureDate'
  | 'interest_rate'
  | 'last_name'
  | 'message'
  | 'mileage'
  | 'monetary_input'
  | 'name'
  | 'password'
  | 'phone_number'
  | 'postal_code'
  | 'privacy_policy'
  | 'terms_of_use'
  | 'timeFrom'
  | 'timeTo'
  | 'vin'

export type PasswordRequirement = {
  label: string
  completed: boolean
}

const emailValidation = (email: unknown): string | null => {
  if (!email) return 'forms.inputs.email.errors.required'
  if (typeof email !== 'string' || !RGX.VALID_EMAIL.test(email))
    return 'forms.inputs.email.errors.invalid'
  return null
}

const termsOfUseValidation = (isChecked: unknown): string | null => {
  if (!isChecked) return 'form.terms_privacy_error'

  return null
}

const feedbackCommentValidation = (comment: unknown): string | null => {
  if (!comment) return 'modal.feedback.form.errors.required'

  if (typeof comment !== 'string' || comment.length >= FEEDBACK_MAX_LENGTH)
    return 'modal.feedback.form.errors.invalid'

  return null
}

const nameValidation =
  (variation?: 'name' | 'first_name' | 'last_name') =>
  (name: unknown): string | null => {
    const validationKey = variation ?? 'name'

    if (!name) return `forms.inputs.${validationKey}.errors.required`

    if (typeof name !== 'string' || !RGX.ONLY_ALPHA.test(name)) {
      return `forms.inputs.${validationKey}.errors.invalid`
    }

    if (name.length < NAME_MIN_LENGTH) {
      return `forms.inputs.${validationKey}.errors.minLength`
    }

    return null
  }

const businessNameValidation = (businessName: unknown): string | null => {
  if (!businessName) return 'forms.inputs.business_name.errors.required'
  if (typeof businessName !== 'string' || !RGX.ONLY_ALPHA_NUMERIC.test(businessName))
    return 'forms.inputs.business_name.errors.invalid'
  return null
}

export const PASSWORD_REQUIREMENT_LIST = [
  { label: 'forms.inputs.password.new.strength_requirements.min_length', completed: false },
  { label: 'forms.inputs.password.new.strength_requirements.uppercase', completed: false },
  { label: 'forms.inputs.password.new.strength_requirements.lowercase', completed: false },
  { label: 'forms.inputs.password.new.strength_requirements.number', completed: false },
  { label: 'forms.inputs.password.new.strength_requirements.symbol', completed: false },
]

export const passwordRequirements = (value: unknown): PasswordRequirement[] => {
  if (!value || typeof value !== 'string') return PASSWORD_REQUIREMENT_LIST

  return [
    { ...PASSWORD_REQUIREMENT_LIST[0], completed: value.length >= 8 },
    { ...PASSWORD_REQUIREMENT_LIST[1], completed: RGX.HAS_ONE_UPPERCASE.test(value) },
    { ...PASSWORD_REQUIREMENT_LIST[2], completed: RGX.HAS_ONE_LOWERCASE.test(value) },
    { ...PASSWORD_REQUIREMENT_LIST[3], completed: RGX.HAS_A_NUMBER.test(value) },
    { ...PASSWORD_REQUIREMENT_LIST[4], completed: RGX.HAS_A_SYMBOL.test(value) },
  ]
}

const passwordValidation = (password: unknown, subType = 'default'): string | null => {
  if (!password) return `forms.inputs.password.${subType}.errors.required`

  if (
    subType === 'new' &&
    passwordRequirements(password).filter(({ completed }) => !completed).length > 0
  ) {
    return 'forms.inputs.password.new.errors.meets_requirements'
  }

  return null
}

/**
 * Ensure that the user has entered a valid VIN.
 *
 * @param {unknown} vin - The VIN entered by the user.
 * @returns {string | null} Returns null if the VIN is valid or an error message.
 */
export const vinValidation = (vin: unknown): string | null => {
  if (!vin || typeof vin !== 'string' || !RGX.ONLY_ALPHA_NUMERIC.test(vin) || vin.length !== 17) {
    return 'forms.inputs.vin.errors.invalid'
  }

  return null
}

/**
 * Ensure that the user has entered a valid mileage value.
 *
 * @param {number} mileage - The mileage of the vehicle.
 * @returns {string | null} Returns null if the mileage is valid or an error message.
 */
export const mileageValidation = (mileage: unknown): string | null => {
  if (!mileage) {
    return 'forms.inputs.mileage.errors.required'
  }
  if (typeof mileage !== 'number' || mileage < 0 || mileage > 999999999) {
    return 'forms.inputs.errors.generic.invalid'
  }

  return null
}

/**
 * Validates the user's phone number.
 *
 * @param phoneNumber - The phone number entered by the user.
 * @returns Returns null if the phone number is valid or an error message.
 */
const phoneValidation = (phone: unknown): string | null => {
  if (!phone) {
    return 'forms.inputs.phone.errors.required'
  }
  if (typeof phone !== 'string' || !RGX.VALID_PHONE.test(phone)) {
    return 'forms.inputs.phone.errors.invalid'
  }

  return null
}

/**
 * Ensures that the user has entered a valid postal code.
 *
 * @param {unknown} postalCode - The postal code entered by the user.
 * @returns {string | null} Returns null if the postal code is valid or an error message.
 */
const postalCodeValidation = (postalCode: unknown): string | null => {
  if (!postalCode) {
    return 'forms.inputs.postal_code.errors.required'
  }
  if (typeof postalCode !== 'string' || !RGX.VALID_POSTAL_CODE.test(postalCode)) {
    return 'forms.inputs.postal_code.errors.invalid'
  }

  return null
}

/**
 * Validates the user's consent.
 *
 * @param {unknown} consent - The consent given by the user.
 * @returns {string | null} Returns null if the consent is checked or an error message.
 */
const consentValidation = (isChecked: unknown): string | null => {
  if (!isChecked) {
    return 'forms.inputs.consent.errors.required'
  }

  return null
}

/**
 * Validates if the date is valid and not in the past.
 *
 * @param date - The date to validate. It can be of any type.
 * @returns Returns a validation error message if the date is invalid, otherwise returns null.
 */
const futureDateValidation = (date: unknown): string | null => {
  if (!date) return 'common:forms.inputs.date.required'
  if (typeof date !== 'string') return 'common:forms.inputs.date.invalid'
  if (!isValid(new Date(date))) return 'common:forms.inputs.date.invalid'
  if (isBefore(startOfDay(parseISO(date)), startOfDay(new Date())))
    return 'common:forms.inputs.date.past'

  return null
}

const timeFormat = 'HH:mm'
/**
 * Validates the `timeFrom` input.
 *
 * @param timeFrom - The input value to validate.
 * @param timeTo - The value of the `timeTo` input.
 * @returns Returns an error message string if validation fails, otherwise returns null.
 */
const timeFromValidation = (timeFrom: unknown, timeTo: unknown): string | null => {
  if (!timeFrom) return 'common:forms.inputs.timeFrom.required'
  const parsedTimeTo = parse(timeTo as string, timeFormat, new Date())
  const parsedTimeFrom = parse(timeFrom as string, timeFormat, new Date())
  if (isAfter(parsedTimeFrom, parsedTimeTo)) return 'common:forms.inputs.timeFrom.invalidRange'

  return null
}

/**
 * Validates the `timeTo` input.
 *
 * @param timeTo - The input value to validate.
 * @param timeFrom - The value of the `timeFrom` input.
 * @returns Returns a validation error message if `timeTo` is falsy, otherwise returns null.
 */
const timeToValidation = (timeTo: unknown, timeFrom: unknown): string | null => {
  if (!timeTo) return 'common:forms.inputs.timeTo.required'
  const parsedTimeTo = parse(timeTo as string, timeFormat, new Date())
  const parsedTimeFrom = parse(timeFrom as string, timeFormat, new Date())
  if (isBefore(parsedTimeTo, parsedTimeFrom)) return 'common:forms.inputs.timeTo.invalidRange'

  return null
}

const monetaryInputValidation = (monetaryValue: unknown): string | null => {
  if (typeof monetaryValue !== 'number' || !RGX.ONLY_FLOATS.test(monetaryValue.toString())) {
    return 'payment_estimator:validation.monetary_value'
  }

  return null
}

const interestRateValidation = (percent: unknown): string | null => {
  if (typeof percent !== 'number' || percent > 100 || percent < 0) {
    return 'payment_estimator:validation.interest_rate'
  }

  return null
}

const messageValidation = (message: unknown): string | null => {
  if (typeof message !== 'string' || !message.trim())
    return 'common:forms.inputs.message.errors.required'

  return null
}

/**
 * This object will be consumed by a validation hook that will get the submit data and automatically return proper translated errors.
 */
export const validationFnPerField: {
  [x in ValidationFields]: (x: unknown, subType?: string) => string | null
} = {
  business_name: businessNameValidation,
  consent: consentValidation,
  email: emailValidation,
  feedbackComment: feedbackCommentValidation,
  first_name: nameValidation('first_name'),
  futureDate: futureDateValidation,
  interest_rate: interestRateValidation,
  last_name: nameValidation('last_name'),
  message: messageValidation,
  mileage: mileageValidation,
  monetary_input: monetaryInputValidation,
  name: nameValidation('name'),
  password: passwordValidation,
  phone_number: phoneValidation,
  postal_code: postalCodeValidation,
  privacy_policy: termsOfUseValidation,
  terms_of_use: termsOfUseValidation,
  timeFrom: timeFromValidation,
  timeTo: timeToValidation,
  vin: vinValidation,
}
