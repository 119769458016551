import { useState } from 'react'

import { type ValidationFields } from '@/utils/validation'

/**
 * Hook that handles form validation for predefined validation functions.
 *
 * @returns {validateForm} - Function that takes the object being submitted and runs through predefined validations based on each key. It returns an error object with the key being the field with the error and value being error message already translated.
 * @returns {errors} - Error object with the key being the field with the error and value being error message already translated. This field gets updated by the validateFom function.
 */
export const useFormValidation = () => {
  const [errors, setErrors] = useState<{ [x in ValidationFields]?: string }>({})

  /**
   * @param validationObj - Object that contains validation pattern for each form
   * @param values - Values being submitted to be validated
   * @returns {Record<string, string>} Error - It returns error object where key is the sma as the values submitted
   */
  const validateForm = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validationObj: Record<string, (value: any) => string | null>,
    values: Record<string, unknown>
  ) => {
    const updatedErrors = Object.keys(values).reduce((acc, item) => {
      const key = item as ValidationFields

      // For each value submitted check if there is a validation function defined for it
      const validationFunction = validationObj[key]

      if (!validationFunction) return acc

      // Triggers validation function if there is one defined for the key
      const errorStringPath = validationFunction(values[key])

      // If nothing is returned means there were no errors
      if (typeof errorStringPath !== 'string') return acc

      // If function returns a string, adds to the error object
      return { ...acc, [key]: errorStringPath }
    }, {})

    setErrors(updatedErrors)
    return updatedErrors
  }

  return { validateForm, errors }
}
